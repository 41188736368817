import React, { createContext } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export const VacancyContext = createContext({
  title: '',
  location: '',
  details: [],
})

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title: string
      recap?: any
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title, recap, flexContent, seo },
  },
  location = {},
  prefix = 'Vacancy_Flexcontent',
}) => (
  <Layout>
    <SEO seo={seo} />
    <VacancyContext.Provider
      value={{
        title,
        location: recap?.location,
        details: recap?.details,
      }}
    >
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </VacancyContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export default PageTemplate
