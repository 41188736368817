/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Misc/Share'
import FormVacancy from 'components/elements/Misc/FormVacancy'

// Hooks
import usePost from 'hooks/usePost'
import useVacancy from 'hooks/useVacancy'

// Interface
import { TextProps } from 'components/flex/Text/Shell'

// Images
import Pin from 'img/pin-active.inline.svg'
import Steps from 'components/elements/Misc/Steps'

const Section = styled.section`
  color: ${({ theme }) => theme.color.dark};
`

const Wrapper = styled.div`
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  margin-top: -10vh;
  background-color: ${({ theme }) => theme.color.light};
`

const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.color.dark};
  min-height: 333px;
  @media (min-width: 992px) {
    margin-left: -6rem;
    margin-top: -5rem;
  }
`

const Image = styled(Plaatjie)`
  border-radius: 24px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.3));
  @media (min-width: 992px) {
    width: 300px;
    height: 285px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2 {
    margin-bottom: 10px;
    font-weight:  ${({ theme }) => theme.font.weight.medium};
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 0;
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  & a:hover {
    color: ${({ theme }) => theme.color.alternate};
    text-decoration: underline;
  }

  & p,
  li {
    line-height: 2;
    font-family: ${({ theme }) => theme.font.family.primary};
  }

  & blockquote {
    & p {
      font-size: 26px;
      line-height: 40px;
      font-weight:  ${({ theme }) => theme.font.weight.medium};
      font-style: italic;
      margin-bottom: 0;

      & strong,
      b {
        font-size: 16px;
        line-height: 16px;
        color: ${({ theme }) => theme.color.secondary};
        font-style: normal;
      }
    }
  }
`

const Location = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.primary};
  }

  & svg {
    width: 33px;
    height: 33px;

    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const Details = styled.div`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }
  & span {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: 200;
  }
`

const Background = styled.div`
  background: #f8f8f8;
  height: 20vh;
`

const Title = styled.h1`
 font-family: ${({ theme }) => theme.font.family.primary};
font-weight: ${({ theme }) => theme.font.weight.medium};
`

const TextPost: React.FC<TextProps> = ({ fields, location }) => {
  const post = usePost()
  const vacancy = useVacancy()

  useEffect(() => {
    if (location.hash === '#FormVacancy') {
      // scroll to element with id FormVacancy -- ty CoPilot
      const element = document.getElementById('FormVacancy')

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  return (
    <Section className="mb-5 pb-lg-5">
      <Background />
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Wrapper className="position-relative p-3 p-sm-5">
              {post.title && (
                <TitleWrapper className="d-flex flex-column flex-lg-row align-items-center pb-5">
                  <div>
                    <Image image={post.image} alt="" className="mb-4 mb-lg-0" />
                  </div>
                  <div className="px-lg-5">
                    <h1 className="font-weight-bold">{post.title}</h1>
                  </div>
                </TitleWrapper>
              )}

              {vacancy.title && (
                <div className="d-flex justify-content-start justify-content-sm-center">
                  <Title>{vacancy.title}</Title>
                </div>
              )}

              {vacancy.location && (
                <Location className="d-flex align-items-center justify-content-start justify-content-sm-center mb-3">
                  <Pin />
                  <h2 className="mb-0">{vacancy.location}</h2>
                </Location>
              )}

              {vacancy.details && (
                <Details className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center mb-5 ms-sm-5">
                  {vacancy.details.map(({ detail }, index) => (
                    <div className="d-flex align-items-center me-5" key={index}>
                      {index === 0 && (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_505_2385)">
                            <path
                              d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM10.8333 10.3608L6.6225 13L5.73834 11.5833L9.16667 9.4375V5H10.8333V10.3608Z"
                              fill="#F29100"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_505_2385">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      {index === 1 && (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_506_2415)">
                            <path
                              d="M13.3692 11.6672H6.63083C5.53567 11.6685 4.48575 12.1041 3.71135 12.8785C2.93696 13.6529 2.50132 14.7029 2.5 15.798V20.0005H17.5V15.798C17.4987 14.7029 17.063 13.6529 16.2886 12.8785C15.5143 12.1041 14.4643 11.6685 13.3692 11.6672Z"
                              fill="#F29100"
                            />
                            <path
                              d="M10 10C12.7614 10 15 7.76142 15 5C15 2.23858 12.7614 0 10 0C7.23858 0 5 2.23858 5 5C5 7.76142 7.23858 10 10 10Z"
                              fill="#F29100"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_506_2415">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      {index === 2 && (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_505_2405)">
                            <path
                              d="M20 6.66632V16.6663H18.3333V7.74132L11.7667 11.9763C11.2408 12.3179 10.6271 12.4997 10 12.4997C9.3729 12.4997 8.75924 12.3179 8.23333 11.9763L0 6.66632L8.23333 1.35548C8.75954 1.0147 9.37308 0.833374 10 0.833374C10.6269 0.833374 11.2405 1.0147 11.7667 1.35548L20 6.66632ZM10 14.1663C9.05365 14.1657 8.12763 13.8916 7.33333 13.3771L3.33333 10.7997V17.0113L3.5775 17.2555C3.65583 17.333 5.53917 19.1663 10 19.1663C14.4608 19.1663 16.3442 17.333 16.4225 17.2555L16.6667 17.0113V10.7997L12.6667 13.3771C11.8724 13.8916 10.9464 14.1657 10 14.1663Z"
                              fill="#F29100"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_505_2405">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      {index === 3 && (
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_505_2395)">
                            <path
                              d="M8.60858 2.37483C8.43363 1.69437 8.03735 1.09142 7.48212 0.660889C6.9269 0.23036 6.24426 -0.0032959 5.54167 -0.0032959C4.83908 -0.0032959 4.15644 0.23036 3.60121 0.660889C3.04599 1.09142 2.6497 1.69437 2.47475 2.37483H0V3.95816H2.47475C2.6497 4.63862 3.04599 5.24157 3.60121 5.6721C4.15644 6.10263 4.83908 6.33628 5.54167 6.33628C6.24426 6.33628 6.9269 6.10263 7.48212 5.6721C8.03735 5.24157 8.43363 4.63862 8.60858 3.95816H19V2.37483H8.60858Z"
                              fill="#F29100"
                            />
                            <path
                              d="M13.4583 6.3338C12.7562 6.33362 12.0738 6.56683 11.5187 6.99675C10.9635 7.42667 10.567 8.02892 10.3914 8.7088H0V10.2921H10.3914C10.5664 10.9726 10.9627 11.5755 11.5179 12.0061C12.0731 12.4366 12.7557 12.6703 13.4583 12.6703C14.1609 12.6703 14.8436 12.4366 15.3988 12.0061C15.954 11.5755 16.3503 10.9726 16.5253 10.2921H19V8.7088H16.5253C16.3497 8.02892 15.9532 7.42667 15.398 6.99675C14.8428 6.56683 14.1605 6.33362 13.4583 6.3338Z"
                              fill="#F29100"
                            />
                            <path
                              d="M5.54167 12.6662C4.83949 12.666 4.15717 12.8992 3.602 13.3291C3.04683 13.7591 2.65029 14.3613 2.47475 15.0412H0V16.6245H2.47475C2.6497 17.305 3.04599 17.9079 3.60121 18.3385C4.15644 18.769 4.83908 19.0027 5.54167 19.0027C6.24426 19.0027 6.9269 18.769 7.48212 18.3385C8.03735 17.9079 8.43363 17.305 8.60858 16.6245H19V15.0412H8.60858C8.43304 14.3613 8.03651 13.7591 7.48133 13.3291C6.92616 12.8992 6.24384 12.666 5.54167 12.6662Z"
                              fill="#F29100"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_505_2395">
                              <rect width="19" height="19" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <span className="ms-2">{detail}</span>
                    </div>
                  ))}
                </Details>
              )}

              <Content content={fields.description} />


              <div className="pt-5">
                <Steps />
              </div>

              {vacancy.title && <FormVacancy vacancyTitle={vacancy.title} />}

              {post.title && (
                <Share
                  heading="Deel dit bericht:"
                  location={location}
                  title={post.title || 'Bericht van PWD'}
                  body={fields.description || 'Bericht van PWD'}
                  quote={post.title}
                  className="d-flex align-items-center mt-5"
                />
              )}
              {vacancy.title && (
                <Share
                  heading="Deel deze vacature:"
                  location={location}
                  title={vacancy.title || 'Vacature van PWD'}
                  body={fields.description || 'Vacature van PWD'}
                  quote={vacancy.title}
                  className="d-flex align-items-center mt-5"
                />
              )}
            </Wrapper>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TextPost
