import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/Shell'

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className={`row ${fields?.columnwidth && 'justify-content-center'}`}>
        <div className={`${fields?.columnwidth ? `col-lg-${fields.columnwidth}` : 'col-lg-12'}`}>
          <ParseContent content={fields.description || ''} />
        </div>
      </div>
    </div>
  </section>
)

export default Text
