import { useContext } from 'react'

import { PostContext } from 'templates/post'

const usePost = () => {
  const context = useContext(PostContext)

  return context
}

export default usePost
