import React from 'react'
import styled from 'styled-components'

const StyledSteps = styled.div`
  & h2 {
    font-weight: 500;
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.family.secondary};
  }
`

const Number = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.family.primary};
  border: 2px solid ${({ theme }) => theme.color.dark};
  border-radius: 100px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  z-index: 2;
  width: 170px;
  text-align: center;

  @media (max-width: 991px) {
    gap: 10px;
  }
`

const StepDescription = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.dark};
  white-space: nowrap;
`

const StepsContainer = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Line = styled.div`
  position: absolute;
  width: 85%;
  background-color: ${({ theme }) => theme.color.dark};
  height: 2px;
  top: 106px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (max-width: 991px) {
    display: none;
  }
`

const Steps = () => (
  <StyledSteps>
    <h2 className="pb-4">In 4 stappen aan de slag</h2>
    <StepsContainer>
      <Line />
      <Step>
        <svg
          width="69"
          height="69"
          viewBox="0 0 69 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.9058 17.3179C42.336 17.3179 43.4955 16.1592 43.4955 14.7299C43.4955 13.3006 42.336 12.1418 40.9058 12.1418C39.4756 12.1418 38.3162 13.3006 38.3162 14.7299C38.3162 16.1592 39.4756 17.3179 40.9058 17.3179Z"
            fill="#63B9E9"
          />
          <path
            d="M45.3118 25.0696H42.9947V27.6975C42.9947 28.8132 42.0896 29.7178 40.9731 29.7178C39.8567 29.7178 38.9514 28.8132 38.9514 27.6975V25.0696H36.6343C35.6597 25.0696 34.8667 25.8619 34.8667 26.836V32.3759H47.0793V26.836C47.0794 25.8619 46.2865 25.0696 45.3118 25.0696Z"
            fill="#63B9E9"
          />
          <path
            d="M57.4651 63.8959V2.02027C57.4651 0.904547 56.5601 0 55.4437 0H2.02148C0.905086 0 0 0.904547 0 2.02027V62.4159C0 66.0288 2.94557 69 6.56416 69H61.8895C59.4461 69 57.4651 66.6915 57.4651 63.8959ZM8.48929 24.2836H22.2346C23.351 24.2836 24.2562 25.1881 24.2562 26.3038C24.2562 27.4195 23.3511 28.3241 22.2346 28.3241H8.48929C7.37276 28.3241 6.46767 27.4195 6.46767 26.3038C6.46767 25.1881 7.37276 24.2836 8.48929 24.2836ZM8.48929 32.3649H22.2346C23.351 32.3649 24.2562 33.2695 24.2562 34.3852C24.2562 35.5009 23.3511 36.4055 22.2346 36.4055H8.48929C7.37276 36.4055 6.46767 35.5009 6.46767 34.3852C6.46767 33.2695 7.37276 32.3649 8.48929 32.3649ZM48.8315 60.6494H8.48929C7.37276 60.6494 6.46767 59.7448 6.46767 58.6291C6.46767 57.5134 7.37276 56.6088 8.48929 56.6088H48.8316C49.9482 56.6088 50.8533 57.5134 50.8533 58.6291C50.8531 59.7447 49.948 60.6494 48.8315 60.6494ZM48.8315 52.568H8.48929C7.37276 52.568 6.46767 51.6635 6.46767 50.5478C6.46767 49.432 7.37276 48.5275 8.48929 48.5275H48.8316C49.9482 48.5275 50.8533 49.432 50.8533 50.5478C50.8531 51.6635 49.948 52.568 48.8315 52.568ZM48.8315 44.4868H8.48929C7.37276 44.4868 6.46767 43.5823 6.46767 42.4665C6.46767 41.3508 7.37276 40.4463 8.48929 40.4463H48.8316C49.9482 40.4463 50.8533 41.3508 50.8533 42.4665C50.8531 43.5823 49.948 44.4868 48.8315 44.4868ZM51.1227 34.3961C51.1227 35.5118 50.2176 36.4164 49.1012 36.4164H32.8455C31.7291 36.4164 30.824 35.5118 30.824 34.3961V26.8359C30.824 23.6338 33.4306 21.029 36.6347 21.029H38.844C36.1935 20.1601 34.2733 17.6656 34.2733 14.7299C34.2733 11.0749 37.2488 8.1013 40.9061 8.1013C44.5634 8.1013 47.5388 11.0749 47.5388 14.7299C47.5388 17.6655 45.6186 20.1601 42.9681 21.029H45.3122C48.5162 21.029 51.1229 23.634 51.1229 26.8359V34.3961H51.1227Z"
            fill="#63B9E9"
          />
          <path
            d="M61.5083 63.8958V63.9123C61.5133 64.4812 61.9929 64.9326 62.5623 64.9326H69.0001V19.9085C69.0001 18.7927 68.095 17.8882 66.9786 17.8882H61.5084V63.8958H61.5083Z"
            fill="#63B9E9"
          />
        </svg>
        <Number>1.</Number>
        <StepDescription>Reageer op een vacature</StepDescription>
      </Step>
      <Step>
        <svg
          width="71"
          height="70"
          viewBox="0 0 71 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5126 53.9446C9.84378 48.3767 6.3776 41.1615 5.57284 33.3784C2.55998 37.187 0.926357 41.8583 0.933887 46.7608C0.938953 49.9494 1.65664 53.1191 3.01658 55.9853L0.212366 64.1461C-0.269697 65.5491 0.0824378 67.0723 1.13145 68.1215C1.86967 68.8597 2.84297 69.2529 3.84393 69.2529C4.26507 69.2529 4.69113 69.1834 5.10666 69.0406L13.2678 66.2362C16.134 67.5961 19.3036 68.3138 22.4923 68.3189C27.4908 68.3256 32.248 66.6297 36.0959 63.504C28.3588 62.804 21.1413 59.473 15.5126 53.9446Z"
            fill="#63B9E9"
          />
          <path
            d="M69.8458 54.7203L65.7745 42.8718C67.737 38.86 68.7741 34.3984 68.781 29.9092C68.7931 22.1035 65.7731 14.723 60.2772 9.1275C54.7803 3.53072 47.4553 0.380398 39.6513 0.256768C31.5592 0.128756 23.953 3.20679 18.2346 8.92501C12.5163 14.6434 9.43771 22.2494 9.56586 30.342C9.68949 38.1458 12.8398 45.4709 18.4363 50.9679C24.0209 56.4531 31.3826 59.4722 39.1716 59.472C39.1869 59.472 39.2031 59.472 39.2182 59.472C43.7075 59.465 48.1691 58.4279 52.1809 56.4654L64.0297 60.5369C64.5234 60.7065 65.0297 60.7892 65.5301 60.7892C66.7196 60.7892 67.8762 60.322 68.7537 59.4444C70.0002 58.1977 70.4186 56.3878 69.8458 54.7203ZM42.0321 40.1374H26.7428C25.5906 40.1374 24.6566 39.2033 24.6566 38.0512C24.6566 36.8989 25.5907 35.9649 26.7428 35.9649H42.0319C43.1842 35.9649 44.1182 36.8991 44.1182 38.0512C44.1182 39.2034 43.1843 40.1374 42.0321 40.1374ZM51.6005 31.5573H26.743C25.5907 31.5573 24.6567 30.6232 24.6567 29.4711C24.6567 28.319 25.5909 27.3848 26.743 27.3848H51.6005C52.7528 27.3848 53.6868 28.319 53.6868 29.4711C53.6868 30.6232 52.7528 31.5573 51.6005 31.5573ZM51.6005 22.977H26.743C25.5907 22.977 24.6567 22.0429 24.6567 20.8907C24.6567 19.7385 25.5909 18.8045 26.743 18.8045H51.6005C52.7528 18.8045 53.6868 19.7386 53.6868 20.8907C53.6868 22.0429 52.7528 22.977 51.6005 22.977Z"
            fill="#63B9E9"
          />
        </svg>
        <Number>2.</Number>
        <StepDescription>Kennismakingsgesprek</StepDescription>
      </Step>
      <Step>
        <svg width="70" height="70" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M81.2786 40.1624C78.0579 32.9434 74.7277 25.4787 76.3596 19.4897C76.5961 18.6221 76.3671 17.6958 75.752 17.0319C75.1369 16.368 74.2206 16.0576 73.3198 16.2083C68.5393 17.0082 64.9024 19.7656 62.2637 24.61C59.3223 15.1707 55.1461 5.85563 44.5711 0.310116C43.7636 -0.113311 42.7918 -0.10259 41.994 0.338942C41.1962 0.780474 40.6828 1.59147 40.632 2.49018C40.0471 12.8423 36.6898 20.1975 29.1292 27.5042C27.7061 23.9224 24.3804 17.4931 16.7025 16.2081C15.8018 16.0576 14.8854 16.3679 14.2703 17.0317C13.6552 17.6958 13.4262 18.6219 13.6627 19.4895C16.4771 31.7635 2.17275 47.184 3.03778 59.9394C2.30807 80.1733 28.5489 90.2984 45.0115 89.9933C64.951 89.9023 86.4594 79.7518 86.9844 59.9524C87.2445 53.5342 84.212 46.7369 81.2786 40.1624ZM56.6531 76.3851C45.5986 79.1174 33.3897 72.9143 29.5817 63.1012H26.2411C22.6872 62.9714 22.6898 57.9566 26.2411 57.8281H28.1887C27.9762 56.1614 27.9766 54.3971 28.1893 52.7306H26.2411C22.6872 52.6009 22.6898 47.586 26.2411 47.4575H29.5834C33.3873 37.6623 45.5655 31.4613 56.6082 34.1683C58.0512 34.4947 58.9519 35.9099 58.6198 37.3288C58.2878 38.7478 56.8488 39.6339 55.4053 39.3068C47.7332 37.4246 39.0705 41.1265 35.5366 47.4575H47.1563C50.7103 47.5873 50.7076 52.6021 47.1563 52.7306H33.606C33.3215 54.3846 33.3212 56.1741 33.6051 57.8281H47.1563C50.7103 57.9578 50.7076 62.9727 47.1563 63.1012H35.5339C39.0704 69.4454 47.7611 73.1495 55.4403 71.2486C56.8829 70.9194 58.3238 71.8021 58.6586 73.2205C58.9934 74.639 58.0955 76.0559 56.6531 76.3851Z" fill="#63B9E9" />
        </svg>

        <Number>3.</Number>
        <StepDescription>Betaalde meeloopdag</StepDescription>
      </Step>
      <Step>
        <svg
          width="69"
          height="70"
          viewBox="0 0 69 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 88">
            <path
              id="Vector"
              d="M0.414071 10.7385C0.782007 9.20708 2.68975 8.6638 3.80906 9.78324L11.2562 17.2344H16.9774V11.5132L9.5401 4.07983C8.42605 2.96577 8.96299 1.06005 10.4868 0.686861C14.0036 -0.174352 19.71 -0.0457759 24.1218 4.36461C27.9818 8.22457 29.2217 13.9862 27.4076 19.0874L50.1658 41.8456C55.267 40.0315 61.0286 41.2714 64.8886 45.1314C69.1881 49.4308 69.4413 55.1963 68.5824 58.7717C68.2144 60.3032 66.3067 60.8464 65.1874 59.727L57.7403 52.2758H52.0191V57.997L59.4545 65.4284C60.5688 66.5427 60.0313 68.4488 58.5071 68.8215C54.9918 69.6811 49.2874 69.5568 44.8748 65.1455C41.0148 61.2855 39.7749 55.5239 41.589 50.4227L18.8308 27.6645C13.7296 29.4786 7.96929 28.2387 4.10799 24.3787C-0.191609 20.0794 -0.444851 14.314 0.414071 10.7385Z"
              fill="#63B9E9"
            />
            <path
              id="Vector_2"
              d="M34.4981 49.0507L17.2509 66.2966C13.7994 69.7481 8.45148 70.1781 4.52952 67.5823L20.1109 52.0023C20.8993 51.2125 20.8993 49.9322 20.1109 49.1424C19.3211 48.3539 18.0407 48.3539 17.2509 49.1424L1.67094 64.7237C-0.924829 60.8018 -0.496244 55.4539 2.95669 52.0023L20.2025 34.7551L34.4981 49.0507Z"
              fill="#63B9E9"
            />
            <path
              id="Vector_3"
              d="M68.7875 6.03738L63.069 17.4731C62.7725 18.0674 62.2038 18.4771 61.5461 18.5715L52.2075 19.9044L43.0751 29.0368L40.2166 26.1782L49.3476 17.0472L50.6818 7.70724C50.7762 7.04954 51.1859 6.48079 51.7803 6.18429L63.2159 0.465778C63.9936 0.0776256 64.9343 0.229921 65.5489 0.844496L68.4088 3.70442C69.0234 4.319 69.1757 5.25973 68.7875 6.03738Z"
              fill="#63B9E9"
            />
          </g>
        </svg>

        <Number>4.</Number>
        <StepDescription>Aan de slag!</StepDescription>
      </Step>
    </StepsContainer>
  </StyledSteps>
)

export default Steps
