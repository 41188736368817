import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/Shell'

// Images
import Check from 'img/check.svg'

const Image = styled(Plaatjie)`
  @media (min-width: 1440px) {
    width: 90%;
  }
  @media (max-width: 1349px) {
    width: 60%;
  }

  @media (max-width: 575.98px) {
    width: 100%;
  }

  & img {
    height: auto !important;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.dark};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px !important;
      line-height: 55px !important;
      margin-bottom: 2rem;
    }
  }

  & ul {
    list-style: none;
    padding-left: 0;
    & li {
      display: flex;
      font-size: 18px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      @media (min-width: 576px) {
        align-items: center;
      }
      &:before {
        position: relative;
        top: 3px;
        content: url(${Check});
        margin-right: 15px;
      }
    }
  }
`

const TextImage: React.FC<TextProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <div className="row">
        <div className="col-md-7 d-flex justify-content-center">
          <Image
            image={fields.image}
            alt=""
            className="mb-5 me-lg-5 mb-md-0 position-relative"
          />
        </div>
        <div className="col-md-5">
          <Content content={fields.description} />
        </div>
      </div>
    </div>
  </section>
)

export default TextImage
