import { useContext } from 'react'

import { ProjectContext } from 'templates/project'

const useProject = () => {
  const context = useContext(ProjectContext)

  return context
}

export default useProject
