import { useContext } from 'react'

import { VacancyContext } from 'templates/vacancy'

const useVacancy = () => {
  const context = useContext(VacancyContext)

  return context
}

export default useVacancy
